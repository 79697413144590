/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import H2 from "./h2"
import Header from "./header"
import { GlobalStyles } from 'twin.macro'
import { MDXProvider } from "@mdx-js/react"

const components = {
  h2: H2
}
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyles />
        <div className="flex flex-col h-screen">
          <Header siteTitle={data.site.siteMetadata.title} menuLinks={data.site.siteMetadata.menuLinks} />
          <main className="mb-auto pt-0">
            <div
              className="prose max-w-[960px] mx-auto"
              style={{
                padding: `0px 1.0875rem 1.45rem`,
              }}
            >
              <MDXProvider components={components}>{children}</MDXProvider>
            </div>
          </main>
          <footer
            className="bg-gray-200 h-36 min-h-[100px] pt-0"
            style={{
              borderTop: `1px solid var(--color-primary-100)`,
            }}
          >
            <div
              className="prose max-w-[960px] mx-auto"
              style={{
                padding: `0px 1.0875rem 1.45rem`,
              }}
            >
              © 2020 – {new Date().getFullYear()} Hannes Strautmann
              {` • `}
              <a href="/impressum/">Impressum</a>
              {` • `}
              <a href="/datenschutz/">Datenschutzerklärung</a>
            </div>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
