import React from "react"
// import {css} from '@emotion/react'
// import {bpMaxSM} from 'lib/breakpoints'

const Container = props => {
  const {
    maxWidth = 720,
    noHorizontalPadding = false,
    noVerticalPadding = false,
    className = '',
    ...restProps
  } = props
  const fullMaxWidth = Number.isNaN(Number(maxWidth))
    ? maxWidth
    : `${maxWidth + (noHorizontalPadding ? 0 : 80)}px`
  // div.style +=
  // ${bpMaxSM} {
  //     padding: ${noVerticalPadding ? 0 : '20'}px
  //       ${noHorizontalPadding ? 0 : '20'}px;
  //   }
  return (
    <div className={`${className} mx-auto w-full`} style={{
        maxWidth: `${fullMaxWidth}`,
        padding: `${noVerticalPadding ? 0 : '40'}px ${noHorizontalPadding ? 0 : '40'}px`
        // bpMaxSM here
      }}
      {...restProps}
    >
      {props.children}
    </div>
  )
}

export default Container
