import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle, menuLinks }) => (
  <header
    style={{
      background: `var(--color-primary-100)`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      className="max-w-[960px] mx-auto flex items-center justify-between"
      style={{
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <div className="prose">
        <h1 className="m-0 site-title">
          <Link className="whitespace-pre" // `nowrap`: one line ; `pre`: preserve the new-line character
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle.replace(` `, `\n`)}
          </Link>
        </h1>
      </div>
      <div>
        <nav>
          <ul className="flex flex-row space-x-6 text-lg">
            {menuLinks.map(link => (
              link.name ? (
                <li key={link.name}>
                  <Link className="text-white" to={link.link}>
                    {link.name}
                  </Link>
                </li>
              ) : ''
            ))}
          </ul>
        </nav>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
